import React, { Component } from "react"
import { Link } from "gatsby"
import css from "./stylesheets/RoomThumbLinks.module.scss"

export default class RoomThumbLinks extends Component {
  render() {
    let items = []
    for (let i = 0; i < this.props.thumbLinks.length; i += 1) {
      let classCurrent = ""
      if (this.props.current === this.props.thumbLinks[i].linkTo) {
        classCurrent = css.thumbCurrent
      }

      items.push(
        <li key={i} className={css.thumb + ` ` + classCurrent}>
          <Link to={this.props.thumbLinks[i].linkTo}>
            <img
              src={this.props.thumbLinks[i].imgSrc.childImageSharp.fluid.src}
              alt=""
            />
          </Link>
        </li>
      )
    }

    return (
      <div className={css.base}>
        <ul>{items}</ul>
      </div>
    )
  }
}
