import React from "react"
import { graphql, Link } from "gatsby"
import css from "./stylesheets/VirtualBuildingRoom.module.scss"
import Seo from "../components/Seo"
import CommonHeader from "../components/CommonHeader"
import ImageAndLinks from "../components/ImageAndLinks"
import RoomThumbLinks from "../components/RoomThumbLinks"
import CommonCartLayout from "../components/shopify/CommonCartLayout"
import CartIcon from "../components/icons/cart.png"

export default function VirtualBuildingRoom({ data }) {
  const { markdownRemark } = data
  const { frontmatter } = markdownRemark
  const siblings = data.allMarkdownRemark.edges
  const slug = markdownRemark.fields.slug
  const cartRef = React.createRef()

  let links = []
  for (let i = 0; i < frontmatter.items.length; i += 1) {
    let post = frontmatter.items[i]
    links.push(
      <Link
        key={i}
        className={`fixedFullScreen__content__overlayLinks`}
        to={post.linkTo}
        style={post.style}
        state={{
          modal: true,
        }}
      >
        {post.text}
      </Link>
    )
  }

  let thumbsLinks = []
  for (let i = 0; i < siblings.length; i += 1) {
    thumbsLinks.push({
      linkTo: siblings[i].node.fields.slug,
      imgSrc: siblings[i].node.frontmatter.roomImage,
    })
  }

  return (
    <CommonCartLayout isCartOpen={false} ref={cartRef}>
      <div className={css.base}>
        <Seo
          lang={`en`}
          title={frontmatter.title}
          ogImage={`https://www.skwat.site${frontmatter.roomImage.childImageSharp.fluid.src}`}
          ogUrl={`https://www.skwat.site${markdownRemark.fields.slug}`}
        />
        <div className={css.head}>
          <CommonHeader
            showCloseButton={false}
            showInstagram={false}
            colorMode={`white`}
            title={`SKWAT<br>MARKET`}
          />
        </div>

        <div className={css.thumbLinks}>
          <RoomThumbLinks thumbLinks={thumbsLinks} current={slug} />
          <button
            className={css.cartButton}
            onClick={() => {
              cartRef.current.doCartOpen()
            }}
          >
            <img src={CartIcon} alt="カートを見る" />
          </button>
        </div>

        <ImageAndLinks
          links={frontmatter.items}
          src={frontmatter.roomImage}
          useModal={true}
        />
      </div>
    </CommonCartLayout>
  )
}

export const pageQuery = graphql`
  query($slug: String!, $buildingSlug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      fields {
        slug
      }
      frontmatter {
        roomImage {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        nextLinkTo
        prevLinkTo
        items {
          linkTo
          style {
            height
            left
            top
            width
            backgroundColor
          }
        }
        title
      }
    }
    allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___sort] }
      filter: { frontmatter: { buildingSlug: { eq: $buildingSlug } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            roomImage {
              childImageSharp {
                fluid(maxWidth: 70, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`
