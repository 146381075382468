import React, { Component } from "react"
import css from "./stylesheets/ImageAndLinks.module.scss"
import { Link } from "gatsby"
import Img from "gatsby-image"

export default class ImageAndLinks extends Component {
  constructor(props) {
    super(props)

    this._elContentArea = React.createRef()
    this._elBase = React.createRef()

    this._padding = 0
    if (!this.props.padding) {
      this._padding = 40
    }

    this.state = {
      contentWidth: `100px`,
      contentHeight: `100px`,
      padding: `${this._padding}px`,
    }
    this._doResize = this._doResize.bind(this)
  }

  componentDidMount() {
    window.addEventListener(`resize`, this._doResize, false)
    this._doResize()
  }
  componentWillUnmount() {
    window.removeEventListener(`resize`, this._doResize)
  }

  /**
   * 画面の中央に来るように計算
   **/
  _doResize(e) {
    if (this.props.objectFit === "cover") {
      this._doCoverResize()
    } else {
      this._doContainResize()
    }
  }

  _doCoverResize() {
    // console.log(`cover モードでリサイズします`)

    const aspectRatio = this.props.aspectRatio
      ? this.props.aspectRatio
      : 0.940969782

    const stageWidth = this._elBase.current.clientWidth
    const stageHeight = this._elBase.current.clientHeight

    let contentWidth = stageWidth
    let contentHeight = stageWidth * aspectRatio

    if (contentHeight < stageHeight) {
      contentHeight = stageHeight
      contentWidth = stageHeight / aspectRatio
    }
    this.setState(state => ({
      contentWidth: `${contentWidth}px`,
      contentHeight: `${contentHeight}px`,
    }))
  }


  _doContainResize() {
    const aspectRatio = this.props.aspectRatio
      ? this.props.aspectRatio
      : 0.940969782

    const isMobile = this._elBase.current.clientWidth < 835

    // モバイルサイズのときは余白を小さくする
    const currentPadding = isMobile ? 0 : this._padding * 2

    const stageWidth = this._elBase.current.clientWidth - currentPadding
    const stageHeight = this._elBase.current.clientHeight - currentPadding

    let contentWidth = stageWidth
    let contentHeight = stageWidth * aspectRatio

    if (contentHeight > stageHeight) {
      contentHeight = stageHeight
      contentWidth = stageHeight / aspectRatio
    }
    this.setState(state => ({
      contentWidth: `${contentWidth}px`,
      contentHeight: `${contentHeight}px`,
    }))
  }

  render() {
    let theImage = this.props.src.childImageSharp.fluid
    let links = []
    const useModal = this.props.useModal === true
    for (let i = 0; i < this.props.links.length; i += 1) {
      let post = this.props.links[i]

      if (post.linkTo) {
        links.push(
          <Link
            key={i}
            className={css.contentOverlayLink}
            to={post.linkTo}
            style={post.style}
            state={{
              modal: useModal,
            }}
          >
            {post.text}
          </Link>
        )
      } else {
        links.push(
          <button
            key={i}
            className={css.contentOverlayLink}
            onClick={post.onClick}
            style={post.style}
          >
            {post.text}
          </button>
        )
      }
    }

    const contentStyle = {
      width: this.state.contentWidth,
      height: this.state.contentHeight,
    }
    return (
      <div className={css.base} ref={this._elBase} style={this.props.style}>
        <div
          className={css.content}
          ref={this._elContentArea}
          style={contentStyle}
        >
          <div>
            {links}
            <Img className={css.contentBackground} fluid={theImage} alt="" />
          </div>
        </div>
      </div>
    )
  }
}
